import AboutUs from './Components/AboutUs';
import Banner from './Components/Banner/index';
import Features from './Components/Features';
import HorizontalNav from './Components/Navigation/horizontal/index'
import VerticalNav from './Components/Navigation/vertical/index';
// import NotificationBar from './Components/NotificationBar/index';

import { useState } from 'react';
import UseCases from './Components/UsCases';
import OurAiJob from './Components/OurAIJob';
import Integrations from './Components/Integrations';
// import FeaturedOn from './Components/FeaturedOn/Index';
import SocialMedia from './Components/SocialMedia';
import ContactForm from './Components/ContactForm';
import Pricing from './Components/Pricing';
import Footer from './Components/Footer';

//React Toast Component for the Notifications
import { ToastContainer } from 'react-toastify';
// import Testimonial from './Components/Testimonial';
// import Demo from './Components/Demo';
import { PopupProvider } from './Context/PopupContext';
import ProductVideo from './Components/Product-Video';
import Blogs from './Components/Blogs';


function App() {
  const [openSidebar, setOpenSidebar] = useState(false);
  return (
    <>
      <PopupProvider>
        <HorizontalNav setOpenSidebar={setOpenSidebar} />
        <VerticalNav openSidebar={openSidebar} />
        {/* <NotificationBar /> */}
        <Banner />
        <Features />
        <AboutUs />
        <OurAiJob />
        <ProductVideo />

        <UseCases />
        <Integrations />
        {/* <FeaturedOn /> */}
        <SocialMedia />
        {/* <Testimonial /> */}
        {/* <Pricing /> */}
        <ContactForm />
        {/* <Demo /> */}
        <Blogs />
        <Footer />
        <ToastContainer />
      </PopupProvider>
    </>
  )
}

export default App;
