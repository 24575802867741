import './style.scss';
import ArrowIcon from "./img/arrow-right.png"
import Mask from "./img/mask.svg"
import MaskBottom from "./img/mask-bottom.svg"

import { useState } from 'react';

import {ContactService} from '../../services/ApiServices';

//Toast
import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    //handling Input Data
    const handleValue = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSUbmit = (e) => {
        e.preventDefault();
        if (formData && Object.keys(formData).length) {
            ContactService(formData);

            //toast Notification on successeful submit
            toast.success('You have succesfully submitted', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
        } else {
        }

        setFormData({
            name: '',
            email: '',
            phone: '',
            message: ''
        });

    }

    return (
        <section className='contact' id='contact'>
            <img src={Mask} className="mask" alt="/" />
            <img src={MaskBottom} className="mask-bottom" alt="/" />

            <div className='container-lg'>
                <h2 className='subtitle'>Fill in the form for your queries and we'll get back to you :)</h2>

                <form onSubmit={handleSUbmit} className='contact-form'>
                    <input type='text' name='name' placeholder='Your Full Name' className='input' id='name' value={formData.name} onChange={handleValue} required />
                    <input type='email' name='email' placeholder='Email' className='input' id='name' value={formData.email} onChange={handleValue} required />
                    <input type='tel' name='phone' placeholder='Phone Number' className='input' id='name' value={formData.phone} onChange={handleValue} required />
                    <textarea name='message' id='message' placeholder='Message' className='user-msg' value={formData.message} onChange={handleValue} required />

                    <div className='btn-wrapper'>
                        <button type="submit" className='btn' >
                            submit
                            <img src={ArrowIcon} className="icon" alt="/" />
                        </button>
                    </div>

                    {/* <ToastContainer /> */}

                </form>
            </div>
        </section>
    )
}

export default ContactForm;