import './style.scss';
import DatabaseImg from "./img/Database.png";
import ProductImg from "./img/product.png";
import PersonalImg from "./img/Personal.png";
import CoinsImg from "./img/coins.png";
import visionImg from "./img/Vision.png";
import journeyImg from "./img/journey.png";





const UseCases = () => {
    return (
        <section className='usecases' id='service'>
            <div className='container-lg'>
                <h2 className='main-title'>Our Primary Use Cases</h2>

                <div className='usecases-list'>
                    <div className='usecase-card'>
                        <div className='usecase-icon'>
                            <img src={CoinsImg} alt='/' />
                        </div>
                        <h2 className='sub-subtitle'>Bank Statement Parsing</h2>
                        <p>Accelerate and enhance accuracy in bank statement parsing for loan processing, utilizing our AI to identify discrepancies and validate financial information.</p>
                    </div>
                    <div className='usecase-card'>
                        <div className='usecase-icon'>
                            <img src={DatabaseImg} alt='/' />
                        </div>
                        <h2 className='sub-subtitle'>Anti-Money Laundering (AML)</h2>
                        <p>Utilize our AI-driven tools to detect and prevent money laundering activities, ensuring regulatory compliance and mitigating financial risks.</p>
                    </div>
                    <div className='usecase-card'>
                        <div className='usecase-icon'>
                            <img src={ProductImg} alt='/' />
                        </div>
                        <h2 className='sub-subtitle'>Case Investigation</h2>
                        <p>Leverage our advanced analytics for deep investigations into financial crimes, uncovering hidden connections and fraudulent activities with precision.</p>
                    </div>
                    <div className='usecase-card'>
                        <div className='usecase-icon'>
                            <img src={PersonalImg} alt='/' />
                        </div>
                        <h2 className='sub-subtitle'>Regulation and Compliance Management</h2>
                        <p>Streamline compliance processes with our AI solutions, maintaining adherence to financial regulations and avoiding legal pitfalls.</p>
                    </div>
                    <div className='usecase-card'>
                        <div className='usecase-icon'>
                            <img src={visionImg} alt='/' />
                        </div>
                        <h2 className='sub-subtitle'>Anomaly Detection in Transactions</h2>
                        <p>Identify and analyze unusual financial activities using our sophisticated algorithms, reducing false positives and enhancing transaction security.</p>
                    </div>
                    <div className='usecase-card'>
                        <div className='usecase-icon'>
                            <img src={journeyImg} alt='/' />
                        </div>
                        <h2 className='sub-subtitle'>Data Management and Analysis</h2>
                        <p>Harness our technology for effective data ingestion, cleaning, enrichment, and conversion into analyzable formats, supporting comprehensive financial analysis.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UseCases;