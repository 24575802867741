import AkashImg from "./img/akash.jpg";
import GirishImg from "./img/girish.png";
import RaviImg from "./img/ravi.png";
import KhushbooImg from "./img/khushboo.jpg";
import AbuImg from "./img/abu.jpeg";
import SheshImg from "./img/shesh.jpg";
import ShivaniImg from "./img/shivani.jpg";
import VandanaImg from "./img/vandana.jpg";
import VivekImg from "./img/vivek.jpg";
import LimeshImg from "./img/limesh.png";
import DishuImg from "./img/dishu.jpg";




const socialData = [
    {name:'Akash Chandra', idLink:'https://www.linkedin.com/in/akash-chandra-centerstageai/', photo:`${AkashImg}`, description:"Akash Chandra, InsightAI Founder and IIT alumnus, spearheads business, technology, and sales, focusing on AI, especially generative AI, to revolutionize FinTech and Retail sectors.", socialPlatform:'LinkedIn'},

    {name:'Limesh Misal', idLink:'https://www.linkedin.com/in/limeshmisal/', photo:`${LimeshImg}`, description:"With 23 years' experience, including 11 as a 2X entrepreneur with successful exits, he's an industrial leader pioneering AI and ML-based analytics and cloud solutions.", socialPlatform:'LinkedIn'},

    {name:'Ravi Sharma', idLink:'https://www.linkedin.com/in/ravi-sharma-75a278b7/', photo:`${RaviImg}`, description:"A results-oriented professional with seven years of diverse experience in delivering strategic insights and fostering business growth through effective cross-functional team management.", socialPlatform:'LinkedIn'},
    
    {name:'Girish Gandhi', idLink:'https://www.linkedin.com/in/girish-g-b8b376169/', photo:`${GirishImg}`, description:"Lead Product Engineer at Insight AI with 6+ years of professional experience. Expertise in Edtech, Fintech, and Generative AI. A perpetual learner fueling innovation across diverse domains.", socialPlatform:'LinkedIn'},
    
    {name:'Shesha Sahu', idLink:'https://www.linkedin.com/in/shesha3291/', photo:`${SheshImg}`, description:"🚀 Backend Software Engineer at InsightAI | 3+ years in IT | Proficient in Python | Committed to pushing the boundaries of software development.", socialPlatform:'LinkedIn'},
    
    {name:'Khushboo Singh', idLink:'https://www.linkedin.com/in/khushboo-singh-894879165/', photo:`${KhushbooImg}`, description:"I'm Khushboo Singh, the visionary Frontend Developer at Insight AI, gracefully navigates the digital landscape, embracing innovation and technology with a passion for endless exploration. 🚀💻", socialPlatform:'LinkedIn'},

    {name:'Vivek Kumar', idLink:'http://www.linkedin.com/in/vivek-kumar-ai-ml', photo:`${VivekImg}`, description:"An IIT Kanpur graduate. Enthusiastic about exploring innovative applications, solving complex problems, and contributing to the cutting edge of artificial intelligence and machine learning.", socialPlatform:'LinkedIn'},

    {name:'Abu Kashan', idLink:'https://www.linkedin.com/in/abu-kashan-134305216/', photo:`${AbuImg}`, description:"I am a final year student at IIT Kanpur with a keen interest in machine learning. At Insight AI, I apply advanced deep learning techniques to innovate and create solutions.", socialPlatform:'LinkedIn'},

    {name:'Shivani Pande', idLink:'https://www.linkedin.com/in/shivani-pande-0aa959235/', photo:`${ShivaniImg}`, description:"Shivani Pande, data science master's student at Dhirubhai Ambani Institute of ICT. Data Science intern at InsightAI, a crucial step in my professional journey. Outside academics, I enjoy reading and painting.", socialPlatform:'LinkedIn'},

    {name:'Vandana Basrani', idLink:'https://www.linkedin.com/in/vandana-basrani-895283203/', photo:`${VandanaImg}`, description:"Vandana Basrani, a final year Electrical Engineering undergrad at IIT Kanpur and Machine Learning intern at InsightAI, brings expertise in Finance, software development, machine learning, and data science.", socialPlatform:'LinkedIn'},

    {name:'Dishu Yadav', idLink:'https://www.linkedin.com/in/dishu-yadav-a5a831221/', photo:`${DishuImg}`, description:"Final year undergrad at Ch Devi Lal University, Sales intern at InsightAI. Dedicated to sales success, exceeding targets. Passionate about content creation and dancing beyond academics.", socialPlatform:'LinkedIn'},

];

export default socialData;