import { useState } from 'react';


import BlogCard from "./BlogCard";

import { blogData } from "./data";

import './style.scss';

const Blogs = () => {
    const [visibleBlogs, setVisibleBlogs] = useState(3);

    const handleViewMore = () => {
        setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 3);
    };

    const handleViewLess = () => {
        setVisibleBlogs((prevVisibleBlogs) => Math.max(prevVisibleBlogs - 3, 0));
    };
    return (
        <div className="blogs" id="blogs">
            <div className="container-lg">
                <h2 className="main-title">Our Blogs</h2>

                <div className="blogs-row">
                    {
                        blogData.slice(0, visibleBlogs).map((item, i) => {
                            return (
                                <BlogCard data={item} key={i} />
                            )
                        })
                    }
                </div>
                <div className='blogs__btn-wrapper'>
                    {visibleBlogs < blogData.length && (
                        <button onClick={handleViewMore} className='blogs__btn'>View More</button>
                    )}
                    {visibleBlogs > 3 && (
                        <button onClick={handleViewLess} className='blogs__btn'>View Less</button>
                    )}
                </div>
            </div>
        </div>
    )
}
export default Blogs;