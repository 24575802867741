import './style.scss';
import Logo from './img/logo.svg';
import LinkedIn from './img/Linkedin.svg';
import Instagram from './img/Instagram.svg';
import Twitter from './img/Twitter.svg';
import Facbook from './img/Facebook.svg';
import Youtube from './img/youtube.svg';

import { useState } from 'react';



import { NewsLetterService } from '../../services/ApiServices';


const Footer = () => {
    const [inputData, setInputData] = useState({ email: '' });


    const handleInput = (e) => {

        setInputData({ ...inputData, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('clicked');
        if (inputData && Object.keys(inputData).length) {
            //toast Notification on successeful submit
            NewsLetterService(inputData);

            console.log(inputData);
            setInputData({ email: '' });
        }

    }
    return (
        <footer className=' footer'>
            <div className='footer-top'>
                <div className='container'>
                    <div className='footer-logo'>
                        <img src={Logo} alt='Insight AI' className='logo' />
                        <p className='footer-content'>Embrace the Future of Productivity: Empower Your Data with InsightAI - Your Pathway to a Smarter, More Efficient Workspace!</p>
                    </div>
                    <div className='contact-info'>
                        <h3 className='small-title bold'>Contact us</h3>
                        <a href="tel:+91-89765433210" className='anchor'>+91-7768944118</a>
                        <a href="mailto:info@pinnacle-technologies.in" className='anchor'>support@insightai.in</a>



                    </div>
                    <div className='newletter'>
                        <h3 className='small-title '>Join our newsletter for exciting updates.</h3>
                        <form className='newsletter-input' onSubmit={handleSubmit}>
                            <input type='email' className='newsletter-email input' name='email' id='newsletter-email' placeholder='Email' value={inputData.email} onChange={handleInput} required />
                            <button className='btn'>Subscribe</button>
                        </form>
                    </div>

                    <div className='locations'>
                        <h3 className='small-title'>Our Locations</h3>
                        <div className='address'>
                            <h4 className='address-city'>Noida</h4>
                            <address className='contact-address'>
                                <p>IHDP Business Park</p>
                                <p> Plot No. 7, Sector 127</p>
                                <p>Noida, Uttar Pradesh 201303</p>
                            </address>
                        </div>
                        <div className='address'>
                            <h4 className='address-city'>New Delhi</h4>
                            <address className='contact-address'>
                                <p>B1/H3, Block B</p>
                                <p> Mohan Cooperative Industrial Estate</p>
                                <p>New Delhi 110044</p>
                            </address>
                        </div>
                    </div>

                </div>
            </div>

            <div className='footer-bottom'>
                <div className='container'>
                    <div className='social-icons'>
                        <a href='https://www.linkedin.com/company/insightai-technologies/' target="_blank" rel="noopener noreferrer" className='icon'><img src={LinkedIn} alt='LinkedIn' /></a>
                        <a href='https://www.instagram.com/insightai.in/' target="_blank" rel="noopener noreferrer" className='icon'><img src={Instagram} alt='instagram' /></a>
                        <a href='https://twitter.com/getInsightAI' target="_blank" rel="noopener noreferrer" className='icon'><img src={Twitter} alt='Twitter' /></a>
                        <a href='https://www.facebook.com/profile.php/?id=61552129252904' rel="noopener noreferrer" target="_blank" className='icon'><img src={Facbook} alt='Facbook' /></a>
                        <a href='https://www.youtube.com/@TeamInsightAI' rel="noopener noreferrer" target="_blank" className='icon'><img src={Youtube} alt='Youtube' /></a>
                    </div>
                    <div className='copy-right'>
                        <span>Pinnacle Technologies and Media Solution Pvt Ltd copyright reserved 2023</span>
                    </div>

                </div>
            </div>

        </footer>
    )
}
export default Footer;