import './style.scss';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import data from './socialData';
import SocialMediaSlide from './SocialMediaSlide';



const SocialMedia =()=>{
    const settings = {
        dots: true,
        arrows:false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,

        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    }

    return (
        <section className='social-media'>
            <div className='container-lg'>
                <h2 className='main-title'>Our Team</h2>

                <Slider {...settings} className='social-media-carousel'>
                    {
                        data.map((item, i) =>{
                            return <SocialMediaSlide key={i} name={item.name} link={item.idLink} photo={item.photo} description={item.description} socialPlatform={item.socialPlatform}/>
                        })
                    }
                </Slider>
            </div>
        </section>
    )
}

export default SocialMedia;