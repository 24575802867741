import Grow from './img/growth.svg';
import Speed from './img/speed.png';
import AML from './img/aml.png';
import Shopify from './img/shopify.svg';
import './style.scss'

const Features = () => {
    return (
        <section className="features">
            <div className="container-lg">
                <h2 className="main-title">Why Choose Us</h2>
                <p style={{
                    textAlign: 'center',
                    fontSize: '1.2rem',
                    color: '#6D6D6D',
                    fontWeight: '250',
                    marginBottom: '2rem'
                }}>InsightAI is at the forefront of combating financial crimes using cutting-edge AI and Big Data technologies. Our dedicated team, operating both in India and globally, is skilled in advanced and generative AI, delivering unparalleled solutions in Anti-Money Laundering, case investigations, and compliance management.</p>
                <div className="features-row">
                    <div className="feature-card">
                        <div className="feature-icon">
                            <img src={AML} alt="/" />
                        </div>
                        <p style={{
                            marginBottom: '2rem',
                            fontWeight: '500',
                            fontSize: '1.2rem'
                        }}>Unmatched Expertise in AI-Driven Fraud Prevention</p>
                        <p style={{
                            color: '#6D6D6D',
                        }}>Our solutions, powered by Generative AI and Natural Language Processing, are specifically tailored to identify and mitigate financial crimes efficiently.</p>
                    </div>

                    <div className="feature-card">
                        <div className="feature-icon">
                            <img src={Speed} alt="/" />
                        </div>
                        <p style={{
                            marginBottom: '2rem',
                            fontWeight: '500',
                            fontSize: '1.2rem'
                        }}>Comprehensive Approach to Financial Crime Management</p>
                        <p style={{
                            color: '#6D6D6D',
                        }}>Our three-step process encapsulates the entire spectrum of financial crime prevention - from data ingestion and analytics to action implementation.</p>
                    </div>

                    <div className="feature-card">
                        <div className="feature-icon">
                            <img src={Grow} alt="/" />
                        </div>
                        <p style={{
                            marginBottom: '2rem',
                            fontWeight: '500',
                            fontSize: '1.2rem'
                        }}>Tailored Solutions with Global Applicability</p>
                        <p style={{
                            color: '#6D6D6D',
                        }}>Our team’s global experience and perspective equip us to design solutions that are not only effective in India but across various international financial landscapes.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;