import './style.scss';


import PricingTop from './PricingTop';
import PricingCardColumn from './PricingCards';

function Pricing() {
    return (
        <div className='pricing-box' id='pricing'>
            <div className='container-lg'>
                <PricingTop />
                {/* <PricingCardColumn /> */}

            </div>
        </div>
    );
}

export default Pricing;