import { createContext, useContext, useState } from "react";

const PopupContext = createContext();

export const PopupProvider = (props)=>{
    const [isPopupOpen, setPopupOpen] = useState(false);

    const openPopup = () =>setPopupOpen(true);
    const closePopup = () =>setPopupOpen(false);

    return(
        <PopupContext.Provider value={{isPopupOpen, openPopup, closePopup} }>
            {props.children}
        </PopupContext.Provider>
    )
};

export const usePopup =() =>useContext(PopupContext);

