import { useState } from "react";

const BlogCard = ({data}) => {
    const {img, date, readingTime, title, author, link} = data;

    return (
        <div className="blog-card">
            <a href={link} target="_blank" rel="noopener noreferrer" className="blog-card__link">
                <div className="blog-card__img">
                    <img src={img} alt="blog-img" />
                </div>
                <div className="blog-card__content">
                    <div className="post-time">
                        <span className="post-date">{date} |</span>
                        <span className="post-read">{readingTime} Read</span>
                    </div>
                    <h2 className="blog-card__title">{title} </h2>
                    <span className="post-writer">{author}</span>
                </div>
            </a>

        </div>
    )
}

export default BlogCard;