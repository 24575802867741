import './style.scss';

import { Link } from 'react-scroll';


const VerticalNav = ({openSidebar}) =>{
    return(
        <div className={openSidebar ? 'side-menu active' : 'side-menu'}>
            <Link spy={true} smooth={true} offset={-150} duration={500} to='home' activeClass="active" className="menu-item">Home</Link>
            <Link to='aboutUs' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="menu-item">About</Link>
            <Link to='service' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="menu-item">Service</Link>
            {/* <Link to='testimonial' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="menu-item">Testimonial</Link> */}
            {/* <Link to='pricing' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="menu-item">Pricing</Link> */}
            <Link to='contact' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="menu-item">Contact Us</Link>        
            <Link to='contact' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="menu-item">Blogs</Link>                   
        </div>
    )
}

export default VerticalNav;