import './style.scss';
import Logo from '../img/logo.png';
import MenuIcon from '../img/menu.svg';

// import { Link, NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import { usePopup } from '../../../Context/PopupContext';

//Demo Form

import DemoForm from '../../Demo/DemoForm';

const HorizontalNav = ({ setOpenSidebar }) => {
    const {openPopup} = usePopup();

    const handleClick = () => { setOpenSidebar((prevState) => !prevState) };

    return (
        <header className='header'>
            <div className="container">
                <Link to='home' spy={true} smooth={true} offset={-150} duration={500} className="logo-container">
                    <img src={Logo} alt='Insight Ai' className='main-logo' />
                </Link>

                <nav>
                    <Link to='home' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="nav-item">Home</Link>
                    <Link to='aboutUs' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="nav-item">About</Link>
                    <Link to='service' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="nav-item">Service</Link>
                    {/* <Link to='testimonial' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="nav-item">Testimonial</Link> */}
                    {/* <Link to='pricing' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="nav-item">Pricing</Link> */}
                    <Link to='contact' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="nav-item">Contact</Link>
                    <Link to='blogs' spy={true} smooth={true} offset={-150} duration={500} activeClass="active" className="nav-item">Blogs</Link>
                    <button className='btn-blue' onClick={openPopup}>Talk to us</button>


                    <Link to='/' className='menu-bar' onClick={handleClick}>
                        <img src={MenuIcon} alt='Insight Ai' />
                    </Link>
                </nav>
            </div>

            <DemoForm />
        </header>
    )
}

export default HorizontalNav;