import './style.scss';
import AboutImg from './img/about-img.png';
import ArrowImg from './img/arrow-right.png';
import ArrowIcon from "./img/arrow.svg";




const AboutUs = () =>{
    return (
        <section className="about-us" id="aboutUs">
            <div className="container-lg">
                <div className='about-us-inner'>
                    <img src={ArrowImg} alt='/' className='arrow-right'/>

                    <div className='about-image'>
                        <img src={AboutImg} alt='/'/>
                    </div>
                    <div className='about-us-content'>
                        <h2>Our Process & Technology</h2>
                        <p>InsightAI leverages state-of-the-art AI technologies, including Generative AI and Natural Language Processing, to expertly manage and analyze vast amounts of financial data.</p>
                        <p>Our process begins with Data Ingestion, where we employ custom algorithms to consume data from diverse sources, including databases, data lakes, and documents. </p>
                        <p>Our use of soph isticated algorithms like GCNN, Random Forest, and GAN minimizes false positives and ensures precise anomaly detection</p>
                        

                        <a href="https://app.insightai.in" target="_blank" rel="noopener noreferrer" className='btn-blue'>Try Our Beta 
                            <img src={ArrowIcon}   className ="icon" alt="/"/>
                        
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs;