import './style.scss';
import JobImg from "./img/job.svg";

const OurAiJob = () =>{
    return(
        <section className='our-ai'>
            <div className='container-sm'>
                <h2 className='main-title'>How our AI does the job</h2>
                <p>Harness the power of advanced artificial intelligence and cutting-edge technology to transform your data into actionable insights.</p>
            </div>

            <div className='our-ai-img'>
                <img src={JobImg} alt='/'/>
            </div>
        </section>
    )
}

export default OurAiJob;