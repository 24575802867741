import { useState } from 'react';
import { ContactService } from '../../services/ApiServices';

//Toast
import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Modal
import Modal from 'react-modal';
import { usePopup } from '../../Context/PopupContext';
import './style.scss'
const DemoForm = () => {
    const [modalFormData, setModalFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const {isPopupOpen, closePopup} = usePopup();


    //handling Input Data
    const handleValue = (e) => {
        const { name, value } = e.target;
        setModalFormData({
            ...modalFormData,
            [name]: value,
        })
    }
    const handleSubmit = (e) => {

        e.preventDefault();

        if (modalFormData && Object.keys(modalFormData).length) {
            ContactService(modalFormData);

            //toast Notification on successeful submit
            toast.success('You have succesfully submitted', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
        }

        setModalFormData({
            name: '',
            email: '',
            phone: '',
            message: ''
        })
    }
    return (
        <Modal
            isOpen={isPopupOpen}
            onRequestClose={closePopup}
            contentLabel="InsightAI Demo Modal"
            appElement={document.getElementById('root')}
        >
            <div className="demo-form-wrapper">
                <form onSubmit={handleSubmit} className="demo-form">
                    <h2 className='main-title'>We are excited to meet you!!</h2>
                    <input type='text' name='name' placeholder='Your Full Name' className='modal-input' id='m-name' value={modalFormData.name} onChange={handleValue} required />
                    <input type='email' name='email' placeholder='Email' className='modal-input' id='m-email' value={modalFormData.email} onChange={handleValue} required />
                    <input type='tel' name='phone' placeholder='Phone Number' className='modal-input' id='m-phone' value={modalFormData.phone} onChange={handleValue} required />
                    <textarea name='message' id='m-message' placeholder='Message' className='modal-msg' value={modalFormData.message} onChange={handleValue} required />

                    <div className='modal-btn-wrapper'>
                        <button type="submit" className='btn-blue' >
                            submit
                            {/* <img src={ArrowIcon} className="icon" alt="/" /> */}
                        </button>
                    </div>
                </form>

            </div>

            <button onClick={closePopup} className='close-btn'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" /></svg>
            </button>
        </Modal>
    )
}

export default DemoForm;