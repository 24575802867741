import './style.scss';
import Logo from './img/logo.svg';
import ArrowDown from './img/arrow-down.svg';
import IntegrationCrousel from './IntegrationCarousel';

const Integrations = () => {
    return (
        <section className='integration'>
            <div className='container-lg'>
                <div className='integration__inner'>
                    <img src={ArrowDown} alt="/" className='arrow-down' />

                    <div className='integration-logo'>
                        <img src={Logo} alt="/" />
                    </div>
                    <h2 className='main-title'>Integrations to process data where it is convenient for you</h2>
                    <IntegrationCrousel />
                </div>
            </div>
        </section>
    )
}

export default Integrations;