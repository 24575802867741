import Modal from 'react-modal';
import DemoForm from '../../Demo/DemoForm';
import { usePopup } from '../../../Context/PopupContext';


const PricingTop = () => {
    const {isPopupOpen, openPopup, closePopup} = usePopup();

    return (
        <div className='price-top'>
            <div className="pricing-top-text">
                <h2 className='pricing-title main-title'>Pricing Plans</h2>
                <p>Unlock the perfect pricing solution for your needs! Connect with our dedicated sales executive today to discuss personalized pricing details for our exceptional products/services.</p>

                <button className="btn-blue" onClick={openPopup}>Contact Us</button>

                {/* modal */}
                <Modal
                    isOpen={isPopupOpen}
                    onRequestClose={closePopup}
                    contentLabel="InsightAI Demo Modal"
                    appElement={document.getElementById('root')}
                >
                    <DemoForm />
                    <button onClick={closePopup} className='close-btn'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" /></svg>
                    </button>
                </Modal>
            </div>
            {/* <div className='toggle-bar'>
                <input type="checkbox" className="checkbox"
                    name="plan" id="plan" />
                <label className="label" htmlFor="plan">
                    <span className="label-text month">Monthly</span>
                    <span className="label-text year">Yearly</span>
                </label>
            </div> */}

        </div>
    )
}

export default PricingTop;