import axios from "axios";

//Toast
import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const contactAPI = 'https://server.insightai.in/user/query/contact_us/';

export const ContactService = (data)=>{

    const contactData = data;

    const request = axios.post(contactAPI, contactData
    );
    request.then((res)=>{
        console.log(res);
    }).catch(err=>{
        console.log(err);
    });

}

//newsLetter

const newletterAPI = 'https://server.insightai.in/user/query/newsletter/';

export const NewsLetterService = (data)=>{

    const newsletterData = data;

    const request = axios.post(newletterAPI, newsletterData
    );
    request.then((res)=>{
        console.log(res);
        const successMessage = res.data.message;
        toast.success(successMessage, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }).catch(err=>{
        console.log(err);

        const errorMessage = err.response.data.email[0];

        toast.error(errorMessage, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        });
    });

}
