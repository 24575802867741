const SocialMediaSlide = ({name, link,photo,description,socialPlatform}) => {
    return (
        <div className='social-media-slide'>
            <div className='user-info'>
                <div className='user-img'>
                    <img src={photo} alt={name} />
                </div>
                <div className='user-name-social'>
                    <span className='user-name'>{name}</span>
                    <a href={link} target="_blank" rel="noreferrer noopener" className='user-social'>{socialPlatform}</a>
                </div>
            </div>
            <p className='feedback'>
                {description}
            </p>
        </div>
    )
}

export default SocialMediaSlide;