import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hadoop from "./img/hadoop.png";
import Databricks from "./img/databricks.png";
import MongoDB from "./img/mongo.png";
import Mssql from "./img/mssql.png";
import Postgres from "./img/postgres.png";
import Snowflakes from "./img/snowflakes.png";
import Synapse from "./img/synapse.png";
import Apache from "./img/apache_spark.png";



const IntegrationCrousel = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ],
    }
    return (
        <>
            <Slider {...settings} className="integration-carousel">
                <div className="integration-slide"><img src={Hadoop} alt="/" /></div>
                <div className="integration-slide"><img src={Databricks} alt="/" /></div>
                <div className="integration-slide"><img src={MongoDB} alt="/" /></div>
                <div className="integration-slide"><img src={Mssql} alt="/" /></div>
                <div className="integration-slide"><img src={Postgres} alt="/" /></div>
                <div className="integration-slide"><img src={Snowflakes} alt="/" /></div>
                <div className="integration-slide"><img src={Synapse} alt="/" /></div>
                <div className="integration-slide"><img src={Apache} alt="/" /></div>

            </Slider>
        </>
    )
}

export default IntegrationCrousel;