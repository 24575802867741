import './style.scss';
import video from "./img/video.gif";
import amlImg from "./img/aml.jpg";

const ProductVideo = ()=>{
    return(
        <section className='product-video'>
            <div className='container-lg'>
                <div className='product-video__inner'>
                    <div className='product-video__video'>
                        <img src={amlImg} alt='InsightAI'/>
                    </div>
                    <div className='product-video__text'>
                        <h2>User Interface and Deployment Flexibility</h2>
                        <p> At InsightAI, we understand the importance of accessibility and flexibility in financial crime prevention solutions. Our simple yet powerful user interface allows users to easily analyze, visualize, and filter fraudulent transactions.</p>
                        <p> Our solutions are cloud-agnostic, offering various deployment options including API-based, cloud-based, and on-premise setups</p>
                        <p>This versatility ensures that regardless of your organizational infrastructure, you can seamlessly integrate InsightAI's solutions into your system, enhancing your financial security and compliance.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductVideo;