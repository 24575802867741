import './style.scss'
import Img from "./img/new-banner.png";
// import ArrowIcon from "./img/icons/arrow-right.png"
import CurlArrow from "./img/icons/curl-arrow.svg"
import BotImg from "./img/icons/blue-bot-2x.png"
// import ProductHunt from './ProductHunt';


const Banner = () =>{
    return (
        <section className="banner" id='home' >
            <div className="container">
                <div className="content">
                    <h1 className="title">Transforming Finance with <span>AI-Powered Security</span></h1>
                    <p>Advanced AI solutions for secure, compliant financial operations</p>

                    {/* <a href="https://app.insightai.in" target="_blank" rel="noopener noreferrer" className="btn">Try our beta
                        <img src={ArrowIcon}   className ="icon" alt="/"/>
                    </a> */}
                    {/* <ProductHunt /> */}
                </div>
                <div className="banner-img">
                    <div className="curl-arrow">
                        <img src={CurlArrow} alt=""/>
                    </div>

                    <img src={Img} alt="InsightAI" className="screen-img"/>

                    <img src={BotImg} alt="InsightAI bot" className="bot-img"/>

                </div>
            </div>
        </section>
    );
}

export default Banner;