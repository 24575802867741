import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Pricing from './Components/Pricing';

const root = ReactDOM.createRoot(document.getElementById('root'));

const appRouter = createBrowserRouter([
    {
        path: '/',
        element: <App />
    },
    {
        path: '/pricing',
        element: <Pricing/>
    }

]);
root.render(
    <RouterProvider router={appRouter}/>
);

